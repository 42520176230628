@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Futura-Bold-Cond";
  src: url("/font/FuturaPTCond-Bold.eot?#iefix") format("embedded-opentype"),
    url("/font/FuturaPTCond-Bold.otf") format("opentype"),
    url("/font/FuturaPTCond-Bold.woff") format("woff"),
    url("/font/FuturaPTCond-Bold.ttf") format("truetype"),
    url("/font/FuturaPTCond-Bold.svg#FuturaPTCond-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

:root {
  /* safe area in ios and android handled */
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-left: env(safe-area-inset-left);
  --safe-area-inset-right: env(safe-area-inset-right);

  --gryffindor: 94 36 30;
  --slytherin: 63 107 33;
  --hufflepuff: 148 106 8;
  --ravenclaw: 32 65 85;
  --neutral: 188 164 125;
  --black: 0 0 0;
  --none: 50 50 50;
}

html {
  font-family: "Nunito", sans-serif;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Nunito", sans-serif;
  background-color: #000000;
  padding: 0;
  font-size: 16px;
}

.will-change-transform {
  will-change: transform;
}
.user-select-none {
  user-select: none;
  touch-action: none;
}
.user-select-auto {
  user-select: auto;
  touch-action: manipulation;
}
.user-select-text {
  user-select: text;
  touch-action: manipulation;
}
.user-select-all {
  user-select: all;
  touch-action: manipulation;
}

.preserve-3d {
  transform-style: preserve-3d;
}

.modalScene main,
.modalScene div,
.modalScene section {
  pointer-events: auto;
}

.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 45%;
  right: 0;
  border-top: 1px solid black;
  transform: rotate(-35deg) scale(2);
}
.clearfix {
  clear: both;
}

.news-content a {
  text-decoration: underline;
  color: #00a3e0;
}
.news-content a:hover {
  text-decoration: none;
}
.news-content p {
  margin: 1rem 0;
}
.news-content iframe {
  margin: 1rem 0;
}
.news-content img {
  display: inline-block;
  margin-right: 1rem;
}

.news-content img.streamer-image {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
  max-width: 40%;
}

.news-content h2 {
  font-size: 1.75rem;
  line-height: 1.75rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.news-content h3 {
  font-size: 1.75rem;
  line-height: 1.75rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
.news-content ul,
.news-content ol {
  margin: 1rem 0;
  padding-left: 1rem;
}

.news-content ul li {
  list-style: disc;
}
.news-content ol li {
  list-style-type: decimal;
}

@layer utilities {
  .text-shadow {
    text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.6);
  }

  .text-shadow-white {
    text-shadow: 0px 0px 0px rgba(255 255 255);
  }
}

@layer components {
  .border-ui {
    @apply border border-solid border-opacity-30 border-black;
  }

  .tutorial {
    @apply bg-tutorial border-t-8 border-tutorial z-10 w-full pb-8 pt-3 px-6;
  }

  .tutorial b {
    @apply text-purple;
  }

  .popup-faq {
    @apply bg-white rounded-20px hidden fixed z-50 top-0 left-0 my-5vh mx-5vw  w-auto max-h-90vh py-3 px-6 overflow-y-auto;
  }

  .popup-faq h2 {
    @apply text-xl font-bold inline;
  }

  .popup-faq a {
    @apply text-darkGray underline;
  }

  .subscribe-button {
    @apply rounded-full self-center bg-purple text-center align-middle block px-10 py-2 h-auto border-solid border-2 text-white text-lg font-bold border-white;
  }

  .create-button-wrapper {
    @apply bg-gradient-to-br from-border-yellow via-yellow to-border-orange rounded-20px shadow-sharp inline-block;
  }

  .create-button {
    @apply bg-gradient-to-br from-yellow to-orange text-black text-vw py-2 px-3 rounded-20px text-center font-black;
  }

  .close-button {
    @apply flex items-center justify-center text-xxs absolute rounded-full top-0 right-0 text-black mr-6 mt-2 w-4 h-4;
  }

  @screen sm {
    .popup-faq {
      @apply w-70vw mx-15vw px-12 py-12;
    }

    .create-button {
      @apply text-2xl;
    }
  }

  @screen md {
    .tutorial {
      @apply p-8 pt-5 w-500px rounded-20px border-0 bg-transparent bg-gradient-to-b from-tutorial-desktop to-transparent;
    }
  }
}

@keyframes galaxytrucker-news {
  0%,
  100% {
    transform: translateY(-5%);
    animation-timing-function: ease-out;
  }
  50% {
    transform: none;
    animation-timing-function: ease-in-out;
  }
}
.animate-galaxytrucker-news {
  animation: galaxytrucker-news 3s infinite;
}
